
function CryptoSliderCard({data,icon}) {
    const isPositiveChangePercent = parseFloat(data?.ticker?.ticker?.price_change_percent) > 0;
    return(
        <div className="hover:bg-[#2F3241] transition-all bg-opacity-50 px-10 py-8 rounded-xl">
            <div className="flex items-center gap-2">
                <img className="w-[24px] h-[24px] rounded-full" src={icon}/>
                <p className="text-surface-main font-bold text-sm">{data?.base_unit.toUpperCase()}</p>
                <p className="text-secondaryTwo font-bold text-sm">{data?.name}</p>
            </div>
            <div>
               <p className="text-surface-main font-bold text-2xl mt-2">{data?.quote_unit.toUpperCase()} {data?.ticker?.ticker?.last}</p>
            </div>
            <div className="flex items-center gap-2 mt-2.5">
                <p className="text-surface-main text-sm">{parseFloat(data?.ticker?.ticker?.volume).toFixed(2)}</p>
                <span className={`${isPositiveChangePercent ? "bg-success" : "bg-danger"} text-surface-main font-bold text-xs px-2 py-0.5 rounded-full`}>{data?.ticker?.ticker?.price_change_percent}</span>
            </div>
        </div>
    )
}

export { CryptoSliderCard}
