import {NAVBAR} from "../../utils/const";

function Nav() {
    return(
        <nav className="flex flex-col md:flex-row md:gap-x-14 gap-y-4 md:gap-y-0 mt-4 md:mt-0">
            {
                NAVBAR.map(({name,link}, index) => (
                    <a href={link} key={index} className="font-dmsans hover:text-primary transition-all px-4  font-semibold text-surface-main text-dark cursor-pointer">{name}</a>
                ))
            }

        </nav>
    )
}

export {Nav}
