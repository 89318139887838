import {Wrapper} from "../../components";
import img1 from "../../assets/1.png";
import img2 from "../../assets/2.png";
import img3 from "../../assets/3.png";
import img4 from "../../assets/4.png";

function HowItWork(){
    return(
        <div className="bg-dark-backgroundOnDark pt-24 pb-28 mt-20">
            <Wrapper>
                <div>
                    <h2 className="text-surface-main font-bold text-5xl text-center">4 Adımda Kripto Para Al/Sat!</h2>
                    <p className="text-secondaryTwo text-xl text-center font-dmsans mt-3">Hızlı ve güvenilir bir şekilde
                        Kripto Para almak ve satmak <span className="text-primary">çok kolay!</span> <br/> Hemen üye
                        olun ve kripto dünyasına adım atın.</p>
                </div>
                <div className="mt-12 flex flex-wrap  md:justify-between justify-center">
                    <div className="flex flex-col items-center justify-center">
                        <img src={img1} alt=""/>
                        <span
                            className="uppercase font-dmsans text-secondaryTwo font-bold text-xs text-center">1.Adım</span>
                        <h2 className="text-surface-main font-bold text-xl">Hemen Üye Ol!</h2>
                        <p className="text-center mt-2 text-secondaryTwo">GMS Kripto Kripto para
                            borsası’na <br/> Kolayca üye Ol!</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <img src={img2} alt=""/>
                        <span
                            className="uppercase font-dmsans text-secondaryTwo font-bold text-xs text-center">2.Adım</span>
                        <h2 className="text-surface-main font-bold text-xl">Üyeliğini Doğrula!</h2>
                        <p className="text-center mt-2 text-secondaryTwo">Kimlik bilgilerinizi girerek KYC adımlarını <br/> tamamlayın </p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <img src={img3} alt=""/>
                        <span
                            className="uppercase font-dmsans text-secondaryTwo font-bold text-xs text-center">3.Adım</span>
                        <h2 className="text-surface-main font-bold text-xl">TL/Kripto Para Yatır!</h2>
                        <p className="text-center mt-2 text-secondaryTwo">Hesabınıza TL veya kripto para yatırarak <br/> işlem yapmaya başlayın.</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <img src={img4} alt=""/>
                        <span
                            className="uppercase font-dmsans text-secondaryTwo font-bold text-xs text-center">4.Adım</span>
                        <h2 className="text-surface-main font-bold text-xl">Kripto Para Al/Sat</h2>
                        <p className="text-center mt-2 text-secondaryTwo">Platformumuzda favori kripto paralarınızı <br/> anında alıp satın</p>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export {HowItWork}
