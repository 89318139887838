import axios from "axios";


const getMarket = async () => {
    const {data} = await axios.get('https://exchange.gmskripto.com/api/v2/peatio/public/markets')
    return data
}

const getCurrencies = async () => {
    const res = await fetch('https://exchange.gmskripto.com/api/v2/peatio/public/currencies')
    const data = await res.json()
    return data
}

const getTickers = async () => {
    const res = await fetch('https://exchange.gmskripto.com/api/v2/peatio/public/markets/tickers')
    const data = await res.json()
    return data
}



export { getMarket, getCurrencies, getTickers }
