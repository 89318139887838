import { Wrapper } from "../../components"

const TradingView = () => {
    return (
        <Wrapper >
            <h3 className="text-white text-5xl text-center font-semibold my-4">Trading View</h3>
            <div className="mt-16">

                <p className="text-white mt-4">
                    TradingView, küresel piyasalardaki fırsatları takip etmek isteyen 60 milyondan fazla yatırımcının tercih ettiği bir grafik platformudur. <a className="text-primary" href="https://tr.tradingview.com/symbols/BTCUSD/">Canlı Bitcoin</a> verileri ve grafiklerini izleyerek en iyi işlem fırsatlarını yakalayabilir, yatırımlarınızı hızlıca gerçekleştirebilirsiniz. <br /> <br />

                    TradingView, ekonomistler ve finans uzmanlarının geniş veri yelpazesine erişimini sağlamak için geliştirilmiş analitik araçlar sunar. <a className="text-primary" href="https://tr.tradingview.com/economic-calendar/">Ekonomik Takvim</a> özelliği ile faiz kararları ve GSYİH açıklamaları gibi önemli ekonomik olayları izleyebilir, piyasalar üzerindeki etkileri analiz edebilirsiniz. <br /> <br />

                    Kripto Çiftleri Tarayıcısı ve gelişmiş teknik analiz araçlarına TradingView üzerinden kolayca ulaşabilirsiniz.
                </p>
            </div>

        </Wrapper>
    );
}

export { TradingView }