import classNames from "classnames";
import {createElement} from "react";

function Button ({children, as = 'button', variant = 'primary', icon, ...props}){
    return createElement(
        as,
        {
            className: classNames(
                "text-white font-regular font-DMSans font-base flex justify-center items-center space-x-2 gap-2",
                {
                    "bg-primary rounded-full py-[7px] px-6": variant === "primary",
                    "rounded-full bg-transparent border-[1px] border-white py-[7px] px-6":
                        variant === "secondary",
                    "bg-transparent": variant === "tetriary",
                }
            ),
            ...props
        },
        <>
            {children}

        </>
    )
}

export {Button}
