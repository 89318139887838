import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const RedirectMobileApp = () => {
  const navigate = useNavigate();

  useEffect(()=>{
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
     
      window.location.href = "https://play.google.com/store/apps/details?id=com.gmskripto.exchange";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    
      window.location.href = "https://apps.apple.com/tr/app/gmskripto-exchange/id6504996507?l=tr";
    } else {
 
      navigate('/');
    }
  },[navigate])

  return (
    <div>Uygulama Mağazasına Yönlendiriliyorsunuz...</div>
  )
}
export  {RedirectMobileApp}