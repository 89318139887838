import {Wrapper,Button} from "../../components";
import {useQuery} from "@tanstack/react-query";
import {getCurrencies, getMarket, getTickers} from "../../utils/GetData";


function MarketList() {
    const { data: marketData, isLoading: marketDataLoading, error: marketDataError } = useQuery({
        queryKey: 'markets',
        queryFn: getMarket,
    });

    const { data: tickersData, isLoading: tickersDataLoading, error: tickersDataError } = useQuery({
        queryKey: 'tickers',
        queryFn: getTickers,
    });

    const { data: currenciesData, isLoading: currenciesDataLoading, error: currenciesDataError } = useQuery({
        queryKey: 'currencies',
        queryFn: getCurrencies,
    });

    if(marketDataLoading || tickersDataLoading || currenciesDataLoading){
        return <div className="text-surface-main">Loading...</div>
    }

    if(marketDataError || tickersDataError || currenciesDataError){
        return <div>Error...</div>
    }

    const combinedData = marketData.map((item) => {
        return {
            ...item,
            ticker: tickersData[item.id]
        }
    })

    const renderMarketList = (market,id) => {
        const icon = currenciesData.find((item) => item.id === market.base_unit).icon_url || ""
        const last = parseFloat(market.ticker.ticker.last);
        const quoteUnit = market.quote_unit.toUpperCase();
        const volume = parseFloat(market.ticker.ticker.volume).toFixed(2);
        const priceChangePercent = parseFloat(market.ticker.ticker.price_change_percent) > 0;
        return(
            <tr key={market.id} className="border-b hover:bg-dark-line border-gray-700">
                <td className="py-3 px-4">{id+1}</td>
                <td className="py-3 px-4 flex items-center">
                    <img src={icon} alt={crypto.name}
                         className="w-6 h-6 mr-2"/>
                    <div>
                        <span>{market?.name.toUpperCase()}</span> <span
                        className="text-secondaryTwo text-sm">  {market?.base_unit.toUpperCase()}</span>
                    </div>
                </td>
                <td className="py-3 px-4">{quoteUnit} {last}</td>
                <td className={`py-3 px-4 ${priceChangePercent > 0 ? 'text-success' : 'text-danger'}`}>
                    {market.ticker.ticker.price_change_percent}
                </td>
                <td className="py-3 px-4">{quoteUnit} {volume}</td>

                <td className="py-3 px-4">
                    <Button onClick={() => window.location.href = `https://exchange.gmskripto.com/protrade/${market.id}`}>
                        Detay
                    </Button>
                </td>
            </tr>
        )
    }

    return (
        <Wrapper>
            <div className="flex justify-between mt-24">
                <h2 className="text-surface-main font-bold text-5xl">Piyasalar</h2>
                <p className="text-surface-main font-bold text-base underline underline-offset-4">Hepsini Gör</p>
            </div>
            <div className="overflow-x-auto mt-6">
                <table className="min-w-full text-white">
                    <thead>
                    <tr>
                        <th className="py-3 px-4 text-left">#</th>
                        <th className="py-3 px-4 text-left">İsim</th>
                        <th className="py-3 px-4 text-left">Fiyat</th>
                        <th className="py-3 px-4 text-left">Değişim</th>
                        <th className="py-3 px-4 text-left">Hacim</th>

                        <th className="py-3 px-4 text-left"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {combinedData?.slice(0,10)?.map((crypto,id) => (
                        renderMarketList(crypto,id)
                    ))}
                    </tbody>
                </table>
            </div>
        </Wrapper>
    )
}

export {MarketList}
