import {Wrapper} from "../../components";
import Logo from "../../assets/logo.png";
import {Link} from "react-router-dom";

function Footer(){
    return(
        <footer>
            <Wrapper>
                <div className="grid grid-cols-12 gap-y-5 md:gap-y-0 mt-20 mb-16">
                    <div className="md:col-start-1 md:col-end-3 col-start-1 col-end-13">
                        <img className="w-[211px] h-[60px]" src={Logo} alt=""/>
                        <h2 className="font-bold text-xl text-surface-main mt-3">7/24 Yanınızdayız 🤙</h2>
                        <a className="text-surface-main" href="mailto:support@gmskripto.com">support@gmskripto.com</a>
                        <p className="text-surface-main mt-5">19 Mayıs Mah. Halaskargazi Cad. Şişli/İstanbul</p>
                    </div>
                    <div className="md:col-start-5 md:col-end-7 col-start-1 col-end-13">
                        <h2 className="text-surface-main uppercase text-xl mb-4">Hakkımızda</h2>
                        <a href="#" className="text-secondaryTwo block">Genel Bakış</a>
                        <a href="#" className="text-secondaryTwo block">Duyurular</a>
                        <a href="#" className="text-secondaryTwo block">Basında Biz</a>
                        <a href="#" className="text-secondaryTwo block">Blog</a>
                        <a href="#" className="text-secondaryTwo block">Ücretler</a>
                        <a href="#" className="text-secondaryTwo block">Limitler ve Kurallar</a>

                    </div>
                    <div className="md:col-start-7 md:col-end-9 col-start-1 col-end-13">
                        <h2 className="text-surface-main uppercase text-xl mb-4">Yasal Bilgiler</h2>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Web-Sitesi-Cerez-Politikasi.pdf"
                           className="text-secondaryTwo block">Çerez Politikası</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Web-Sitesi-Aydinlatma-Metni.pdf"
                           className="text-secondaryTwo block">Aydınlatma Metni</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Veri-Sorumlusuna-Basvuru-Formu.pdf"
                           className="text-secondaryTwo block">Veri Sorumlusuna Başvuru Formu</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Suc-Gelirlerinin-Aklanmasinin-Ve-Terorun-Finansmaninin-Onlenmesine-Iliskin-Uyum-Politikasi.pdf"
                           className="text-secondaryTwo block">Uyum Politikası</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Ticari-Elektronik-İleti-Onayi.pdf"
                           className="text-secondaryTwo block">Ticari Elektronik İleti Onayı</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Operasyonel-Risk-Politikasi.pdf"
                           className="text-secondaryTwo block">Risk Politikası</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Kullanici-Sozlesmesi.pdf"
                           className="text-secondaryTwo block">Kullanıcı Sözleşmesi</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Kurumsal-Bilgi-Guvenligi-Politikasi.pdf"
                           className="text-secondaryTwo block">Bilgi Güvenliği Politikası</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Kisisel-Verilerin-Islenmesi-Ve-Korunmasi-Politikasi.pdf"
                           className="text-secondaryTwo block">KVKK Politikası</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Kyc-Politikasi.pdf"
                           className="text-secondaryTwo block">KYC Politikası</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Musteri-Sikayet-Yonetim-Politikasi.pdf"
                           className="text-secondaryTwo block">Müşteri Şikayet Politikası</a>
                        <a target="_blank" href="https://gmskripto.com/sozlesme/GmsKripto-Acik-Riza-Metni.pdf"
                           className="text-secondaryTwo block">Açık Rıza Metni</a>
                        <Link target="_blank" to="/privacy-policy"
                           className="text-secondaryTwo block">Gizlilik Politikası</Link>
                    </div>
                    <div className="md:col-start-9 md:col-end-11 col-start-1 col-end-13">
                        <h2 className="text-surface-main uppercase text-xl mb-4">Destek</h2>
                        <a href="#" className="text-secondaryTwo block">Yardım Merkezi</a>
                        <a href="#" className="text-secondaryTwo block">S.S.S</a>
                        <Link to="/tradingview" className="text-secondaryTwo block">Tradingview</Link>
                    </div>
                </div>
            </Wrapper>
            <div className="bg-[#222630] md:h-[60px]">
                <Wrapper>
                    <p className="text-secondaryTwo md:leading-[60px]">Copyright © 2024 GMS GLOBAL KRİPTO VARLIK ALIM SATIM PLATFORMU ANONİM ŞİRKETİ</p>
                </Wrapper>
            </div>
        </footer>
    )
}

export {Footer}
